<template>
  <validation-observer
    ref="observer"
    v-slot="{ pristine }"
  >
    <aom-skeleton-loader v-if="isLoading" />
    <b-card
      v-else
      title="Edit Profile"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <validation-provider
              v-slot="validationContext"
              ref="first-name"
              :rules="{ required: true }"
              vid="first-name"
              name="First name"
            >
              <b-form-group
                label="First name"
                label-for="first-name"
              >
                <b-form-input
                  v-model="championEdit.first_name"
                  class="mb-1"
                  placeholder="First name"
                  name="first-name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              v-slot="validationContext"
              ref="last-name"
              :rules="{ required: true }"
              vid="last-name"
              name="Last name"
            >
              <b-form-group
                label="Last name"
                label-for="last-name"
              >
                <b-form-input
                  v-model="championEdit.last_name"
                  class="mb-1"
                  placeholder="Last name"
                  name="last-name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
  
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="email"
              :rules="{ required: true, email: true }"
              vid="email"
              name="Email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  v-model="championEdit.email"
                  class="mb-1"
                  placeholder="Email"
                  name="email"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
  
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="name"
              :vid="`phone-number`"
              rules="required|aom_phone"
              name="Phone"
            >
              <b-form-group
                label="Phone"
                label-for="phone"
              >
                <aom-tel-input
                  id="phone"
                  v-model="championEdit.phone"
                  @validate="checkValidatePhone"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <b-form-group
              label="Email Notifications"
              label-for="email_notifications"
            >
              <b-form-checkbox
                v-model="championEdit.emailNotification"
                checked="true"
                name="email_notifications"
                switch
                inline
              />
            </b-form-group>
          </b-col>
        </b-row>

        <div class="mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            @click="onSubmit"
          >
            <b-spinner
              v-if="isSubmitting"
              small
            />
            <span v-if="isSubmitting">Updating...</span>
            <span v-else>Submit</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            :disabled="pristine"
            @click="clearForm"
          >
            Reset
          </b-button>
        </div>
      </b-form>
    </b-card>
  </validation-observer>
</template>
  
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
  BCard,
  BFormCheckbox,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
//eslint-disable-next-line
import { required, email} from "@validations";
import { getValidationState } from "@/libs/utils";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import { authService, usersService } from "@/services";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { mapGetters } from 'vuex';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const champion = {
  first_name: null,
  last_name: null,
  email: null,
  phone: null,
  emailNotification: false
};
export default {
  name: 'ProfileChampion',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormCheckbox,
    BButton,
    AomSkeletonLoader,
    BSpinner
  },
  data() {
    return {
      initialChampion: {...champion},
      championEdit: {...champion},
      isLoading: false,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId']),
  },
  created() {
    this.fetchChampionUser();
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;
      try {
        const payload = {
          first_name: this.championEdit.first_name,
          last_name: this.championEdit.last_name,
          email: this.championEdit.email,
          phone: this.championEdit.phone,
          user_program_settings: [
            {
              program_id: this.defaultProgramId,
              email_notifications: this.championEdit.emailNotification
            }
          ]
        };
        const { data } = await usersService.updateSelf(payload);
        this.setChampion(data);
        this.$toast(makeSuccessToast('Profile Updated Successfully.'));
      } catch (e) {
        const { status, data } = e.response;
        if (status === 409) {
          this.$toast(makeErrorToast(data.message));
        } else {
          console.log(e);
          this.$toast(makeErrorToast(e));
        }
      } finally {
        this.isSubmitting = false;
      }
    },
    async fetchChampionUser() {
      try {
        this.isLoading = true;
        const response = await authService.getUserProfile();
        const { data } = response;
        this.setChampion(data);
      } catch(e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    clearForm() {
      this.championEdit = { ...this.initialChampion };
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    setChampion(data) {
      if (data) {
        const userProgramSettings = data.user_program_settings.find(s => s.program_id == this.defaultProgramId);
        // Use for reset form
        this.initialChampion = {
          ...this.initialChampion,
          ...data
        };

        this.championEdit = {
          ...this.championEdit,
          ...data
        };
        this.championEdit.emailNotification = userProgramSettings?.email_notifications;
        this.initialChampion.emailNotification = userProgramSettings?.email_notifications;
      }

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    checkValidatePhone(phone) {
      if (phone) {
        const initialPhone =
          parsePhoneNumberFromString(
            this.initialChampion.phone,
            phone.countryCode
          ) || {};
        if (phone.nationalNumber == initialPhone.nationalNumber) {
          this.$nextTick(() => {
            this.$refs.observer.reset();
          });
        }
      }
    }
  },
  setup() {
    return {
      getValidationState,
    };
  },
};
</script>
  